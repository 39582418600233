// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pl-index-js": () => import("./../../../src/pages/pl/index.js" /* webpackChunkName: "component---src-pages-pl-index-js" */),
  "component---src-pages-pl-psychotest-js": () => import("./../../../src/pages/pl/psychotest.js" /* webpackChunkName: "component---src-pages-pl-psychotest-js" */),
  "component---src-pages-psychotest-js": () => import("./../../../src/pages/psychotest.js" /* webpackChunkName: "component---src-pages-psychotest-js" */),
  "component---src-templates-blog-post-archive-js": () => import("./../../../src/templates/blog-post-archive.js" /* webpackChunkName: "component---src-templates-blog-post-archive-js" */),
  "component---src-templates-blog-post-archive-pl-js": () => import("./../../../src/templates/blog-post-archive-pl.js" /* webpackChunkName: "component---src-templates-blog-post-archive-pl-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-blog-post-pl-js": () => import("./../../../src/templates/blog-post-pl.js" /* webpackChunkName: "component---src-templates-blog-post-pl-js" */),
  "component---src-templates-cats-post-archive-js": () => import("./../../../src/templates/cats-post-archive.js" /* webpackChunkName: "component---src-templates-cats-post-archive-js" */),
  "component---src-templates-cats-post-archive-pl-js": () => import("./../../../src/templates/cats-post-archive-pl.js" /* webpackChunkName: "component---src-templates-cats-post-archive-pl-js" */)
}

